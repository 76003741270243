import React, { useEffect, useMemo, useState } from 'react';
import './NEStepEmailInput.sass';
import { useNESteps } from 'context/NEStepsContext';

import { FormActionButtons } from 'components/FormHelper/FormActionButtons';
import { InputField } from 'components/FormHelper/InputField';
import { useFormik } from 'formik';
import { YNEEmailSchema } from 'components/NEValidation/NEEmailValidation';
import { FFormDebug } from 'utils/FormUtils';
import { getBUEmail, getTenantId } from 'utils/tenantUtil';
import { getEmailStatusByEmail } from 'api/installer/GetEmailStatusByEmail';
import { isNull } from 'lodash';
import { ErrorBanner } from 'components/ErrorBanner/ErrorBanner';
import { CURRENT_TEST_SUITE, shouldFillTestData } from 'components/NEForm/NETestdata/NETestSetup';
import {
  EIV_STATUS_ALLOWED_TO_REGISTER_EXISTING_EMAIL,
  NE_ACTIVE_STEP,
  TENANT_ID,
  TENANT_TO_NAME,
} from 'global/constants';
import { FormSection } from 'components/FormHelper/FormSection';

const forgotEmail = {
  [TENANT_ID.AVA]:
    'https://account.avacon-netz.de/iconnect/s/login/ForgotPassword?language=de&appID=601007',
  [TENANT_ID.EDI]:
    ' https://account.e-dis-netz.de/iconnect/s/login/ForgotPassword?language=de&appID=601032',
  [TENANT_ID.SHNG]:
    ' https://account.sh-netz.com/iconnect/s/login/ForgotPassword?language=de&appID=601033',
  [TENANT_ID.BAG]:
    'https://account.bayernwerk-netz.de/iconnect/s/login/ForgotPassword?language=de&appID=601036',
};

const ERRORS = {
  ACTIVE: (
    <div>
      Sie haben bereits einen Zugang bei uns/einem unserer Partnerunternehmen und Sie sind im
      Installateurverzeichnis mit Ihrer E-Mail-Adresse eingetragen. Eine Verlängerung/Aktualisierung
      Ihrer Eintragung ist über unser Informationsportal e-fix zu beantragen. Zur Wiederherstellung
      Ihres Passworts nutzen Sie die{' '}
      <a className="link" href={forgotEmail[getTenantId()]}>
        Passwort-Vergessen-Funktion
      </a>
      . Für die Anmeldung rund um einen Netzanschluss steht Ihnen „mein.Auftragsportal“ zur
      Verfügung.
    </div>
  ),
  PENDING: (
    <div>
      Ihre E-Mail ist bei uns im System hinterlegt, die Registrierung wurde nicht abgeschlossen.
      Wenden Sie sich bitte per E-Mail an uns, damit wir Ihnen eine neue Registrierungsmail zusenden
      können. Ihr Account wird verwaltet durch uns / unsere Partnerunternehmen:{' '}
      {TENANT_TO_NAME[getTenantId()]}. Kontakt E-Mail zur Klärung:{' '}
      <a className="link" href={`mailto:${getBUEmail()}`}>
        {getBUEmail()}
      </a>
    </div>
  ),
  DUPLICATE: (
    <div>
      Sie haben bereits einen Zugang bei uns/einem unserer Partnerunternehmen und Sie sind im
      Installateurverzeichnis mit Ihrer E-Mail-Adresse eingetragen. Wenden Sie sich bitte per E-Mail
      an uns, damit wir Ihren Zugang prüfen und Ihre Freischaltung veranlassen können. Kontakt
      E-Mail zur Klärung:{' '}
      <a className="link" href={`mailto:${getBUEmail()}`}>
        {getBUEmail()}
      </a>
    </div>
  ),
  ERROR: (
    <div>
      Leider besteht aktuell ein Fehler in unserem System. Versuchen Sie es bitte zu einem späteren
      Zeitpunkt erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an uns:{' '}
      <a className="link" href={`mailto:${getBUEmail()}`}>
        {getBUEmail()}
      </a>
      `
    </div>
  ),
};

const NEStepEmailInput = () => {
  const { activeStep, setActiveStep, setSubmittedValuesWithStep } = useNESteps();
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: shouldFillTestData() ? CURRENT_TEST_SUITE.step0.email : '',
    },
    validationSchema: YNEEmailSchema,
    validateOnMount: true,
    enableReinitialize: true,
    validateOnChange: true,
  });
  const checkEmail = async () => {
    if (formik.values.email && Object.keys(formik.errors).length == 0) {
      setIsLoading(true);
      setErrorMessage('');
      getEmailStatusByEmail(formik.values.email)
        .then((response) => {
          setIsLoading(false);

          switch (response.status) {
            case 200:
              if (response.data.ciam_status === 'Active') {
                if (
                  EIV_STATUS_ALLOWED_TO_REGISTER_EXISTING_EMAIL.includes(
                    response.data.status_strom
                  ) &&
                  EIV_STATUS_ALLOWED_TO_REGISTER_EXISTING_EMAIL.includes(response.data.status_gas)
                ) {
                  setSubmittedValuesWithStep(NE_ACTIVE_STEP.EMAIL_INPUT, formik.values);
                  setActiveStep(NE_ACTIVE_STEP.BRANCH_SELECTION);
                  return;
                } else {
                  setErrorMessage(ERRORS.ACTIVE);
                }
              } else if (response.data.ciam_status === 'pending') {
                setErrorMessage(ERRORS.PENDING);
              } else if (isNull(response.data.ciam_status)) {
                setErrorMessage(ERRORS.PENDING);
              }
              return;
            case 409:
              setErrorMessage(ERRORS.PENDING);
              return;
            default:
              setErrorMessage(ERRORS.ERROR);
              return;
          }
        })
        .catch(({ response }) => {
          setIsLoading(false);
          if (response) {
            switch (response.status) {
              case 404:
                setSubmittedValuesWithStep(NE_ACTIVE_STEP.EMAIL_INPUT, formik.values);
                setActiveStep(NE_ACTIVE_STEP.BRANCH_SELECTION);
                return;
              case 200:
                if (response.data.ciam_status === 'Active') {
                  if (
                    EIV_STATUS_ALLOWED_TO_REGISTER_EXISTING_EMAIL.includes(
                      response.data.status_strom
                    ) &&
                    EIV_STATUS_ALLOWED_TO_REGISTER_EXISTING_EMAIL.includes(response.data.status_gas)
                  ) {
                    setSubmittedValuesWithStep(NE_ACTIVE_STEP.EMAIL_INPUT, formik.values);
                    setActiveStep(NE_ACTIVE_STEP.BRANCH_SELECTION);
                    return;
                  } else {
                    setErrorMessage(ERRORS.ACTIVE);
                  }
                } else if (response.data.ciam_status === 'pending') {
                  setErrorMessage(ERRORS.PENDING);
                } else if (response.data.ciam_status === 'null') {
                  setErrorMessage(ERRORS.PENDING);
                }
                return;
              case 409:
                setErrorMessage(ERRORS.PENDING);
                return;
              default:
                setErrorMessage(ERRORS.ERROR);
                return;
            }
          }
        });
    }
  };
  const hasErrors = useMemo(() => {
    return !formik.isValid || errorMessage != '';
  }, [formik.values.email, formik.errors.email, errorMessage, formik.isValid]);

  useEffect(() => {
    setErrorMessage('');
  }, [formik.values.email]);
  const onBack = () => {
    window.history.back();
  };

  const onSubmit = () => {
    checkEmail();
  };
  if (activeStep !== NE_ACTIVE_STEP.EMAIL_INPUT) return null;

  return (
    <div className="ne-step-email-input">
      <div className="ne-step-email-input__sub-text">
        Bitte geben Sie hier Ihre gültige E-Mail-Adresse an, die von uns als Kontakt zu Ihnen
        genutzt wird. Nach einer erfolgreichen Eintragung ins Installateurverzeichnis nutzen Sie
        Ihre E-Mail-Adresse gleichzeitig für die Zugänge zu unseren Systemen. Für Ihre Firma
        erhalten Sie nur einen Zugang.
      </div>
      <div className="ne-step-email-input__email">
        <InputField
          fullWidth
          infoText={''}
          key={'email'}
          label="E-Mail-Adresse *"
          name="email"
          oldValue={''}
          variant="standard"
          value={formik.values.email}
          onChange={formik.handleChange}
          disabled={isLoading}
          error={Boolean(formik.errors.email)}
          helperText={formik.errors.email || ''}
        />
      </div>
      <FormSection>
        <div>
          Mit der Eintragung ins Installateurverzeichnis erhalten Sie Zugang zu den
          Netzanschlussportalen aller Partnerunternehmen:
        </div>
        <div>
          <a
            href="https://www.avacon-netz.de/de/meinauftragsportal.html"
            target="_blank"
            rel="noreferrer"
            className="link">
            Avacon Netz GmbH
          </a>
          ,{' '}
          <a
            href="https://www.bayernwerk-netz.de/de/meinauftragsportal.html"
            target="_blank"
            className="link"
            rel="noreferrer">
            Bayernwerk Netz GmbH
          </a>
          ,{' '}
          <a
            href="https://www.e-dis-netz.de/de/meinauftragsportal.html"
            target="_blank"
            rel="noreferrer"
            className="link">
            E.DIS Netz GmbH
          </a>
          ,{' '}
          <a
            href="https://www.elbenergie.com/de/meinauftragsportal.html"
            target="_blank"
            rel="noreferrer"
            className="link">
            ElbEnergie GmbH
          </a>
          ,{' '}
          <a
            href="https://www.hansegas.com/de/meinauftragsportal.html"
            target="_blank"
            rel="noreferrer"
            className="link">
            HanseGas GmbH
          </a>
          ,{' '}
          <a
            href="https://www.sh-netz.com/de/meinauftragsportal.html"
            target="_blank"
            rel="noreferrer"
            className="link">
            NordNetz GmbH
          </a>{' '}
          und{' '}
          <a
            href="https://www.sh-netz.com/de/meinauftragsportal.html"
            target="_blank"
            rel="noreferrer"
            className="link">
            Schleswig-Holstein Netz GmbH
          </a>
        </div>
        <ErrorBanner>{errorMessage}</ErrorBanner>
      </FormSection>
      <div className="ne-form__action-buttons">
        <FormActionButtons
          loading={isLoading}
          hasErrors={hasErrors}
          titleLeft="Zurück"
          titleRight={activeStep === NE_ACTIVE_STEP.FORM_UPLOAD ? 'Eintrag einreichen' : 'Weiter'}
          onClickLeft={onBack}
          onClickRight={onSubmit}
          errorMessage={''}
        />
      </div>
      <FFormDebug values={formik.values} errors={formik.errors}></FFormDebug>
    </div>
  );
};

export { NEStepEmailInput };
